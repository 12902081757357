export default [
    {
        name: 'Carpets',
        filename: 'carpets',
        text: 'If you like something warm underfoot you can opt from a wide range of carpet styles in our range. From deep pile to a spectrum of colours we have something to suit all interiors and budgets.'
    },
    {
        name: 'Vinyl',
        filename: 'vinyl',
        text: 'Today\'s vinyl floor coverings offer a high quality, lasting finish at wallet friendly price. We stock a range of material finishes that are durable and easy to maintain, especially from messy kids.'
    },
    {
        name: 'Laminate',
        filename: 'laminate',
        text: ' For those looking for a cost effective solution to achieve a realistic wooden finish why not try our laminate flooring? This material comes in a wide variety of colours and patterns, a very popular choice as it’s very hard wearing and easy to keep clean. '
    },
    {
        name: 'Engineered Wood',
        filename: 'engineered-wood',
        text: 'For a high end wooden floor finish, look no further than our Engineered Wood . Our engineered boards come with a 20+ year warranty. These boards can be sanded and oiled in years to come to bring them back into pristine condition.'
    },
    {
        name: 'Luxury Vinyl Tiles',
        filename: 'luxury-tiles',
        text: 'Our luxury vinyl tiles are mounted on durable plywood boards for an quality finish. Available in a wide palette of colours and brands to suit your interior, including Karndean and Amtico.'
    },
    {
        name: 'Carpet Tiles',
        filename: 'carpet-tiles',
        text: 'Commonly used in office environments, our carpet tiles are extremely hard wearing and durable. They come in a selection of colours and patterns to suit your interior needs.'
    },
]