import React from 'react'
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from '../components/modules/HeroBanner'
import CardList from '../components/modules/CardList'


const Services = () => {
    return (
        <Layout>
        <SEO title="Sales &amp; Installation Services" description="Find out more about the wide range of flooring solutions that Barron Carpets retails and installs." />
        <Hero alt="Carpet being glued" filename="carpet-glue" title="Find out about our services below" label="Contact Us"  />
        <section className="container section-spacer dark">
        <h1>Our Services</h1>

        <CardList />
        </section>
        </Layout>
    )
}

export default Services